import React, { useEffect, useState } from "react";
import CustomLink from "../../primitive/CustomLink";
// import learn_more_img_1 from "../../../assets/images/learn_more_img-1.png";
// import learn_more_img_2 from "../../../assets/images/learn_more_img-2.png";
// import learn_more_img_3 from "../../../assets/images/learn_more_img-3.png";
import initial_white_icon from "../../../assets/icons/inital_white_arrow.svg";
import hover_white_icon from "../../../assets/icons/hover_white_arrow.svg";
import axios from "axios";
import HomePageBlog from "../BlogSection/HomePageBlog";

import { useTranslation } from "react-i18next";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const BLOG_ID = process.env.REACT_APP_GOOGLE_BLOG_ID;

const LearnMoreCard = () => {
  const { t } = useTranslation("home");
  const [allPosts, setAllPosts] = useState([]);
  const getAllPosts = async () => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID}/posts?key=${API_KEY}`
      );
      setAllPosts(response.data.items);
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  return (
    <div className="container mx-auto flex flex-col items-center space-y-4 md:space-y-0 md:flex-row py-8 md:py-16">
      <div className="w-full md:w-[40%] space-y-4 md:space-y-8">
        <h3 className="text-2xl md:text-4xl font-test-sohne-medium max-w-xs">
          {t("latestNews.latestBold")}
        </h3>
        <div>
          <CustomLink
            to="/blog"
            title={t("latestNews.latestBtn")}
            className="bg-Ebony text-sm md:text-base text-white hover:bg-ShipGrey"
            inital_arrow={initial_white_icon}
            hover_arrow={hover_white_icon}
          />
        </div>
      </div>
      <div className="w-full md:w-[60%]">
        <div className="flex flex-row -space-x-32">
          {allPosts?.slice(0, 3)?.map((post) => (
            <HomePageBlog key={post?.id} post={post} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LearnMoreCard;
