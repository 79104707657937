import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";


const HomePageBlog = ({ post }) => {
  const elementRef = useRef();
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    const desc = elementRef?.current?.querySelector("p");
    const img = elementRef?.current?.querySelector("img");
    if (desc) {
      setDescription(desc.textContent);
    }
    if (img) {
      setImage(img?.src);
    }
  }, []);
  return (
    <div className="rounded-xl p-2 w-80 bg-white shadow-2xl shadow--white relative h-[500px]">
      <div
        dangerouslySetInnerHTML={{ __html: post?.content }}
        ref={elementRef}
        className="hidden"
      />
      <div className="h-60">
        <img src={image} className="w-full h-full" alt="" />
      </div>
      <div className="pt-2 space-y-6">
        <div className="space-y-2">
          <p className="text-SunsetOrange text-[9px] md:text-[11px]">
            {moment(post?.date_posted).format("MMMM do, YYYY")}
          </p>
          <h3 className="font-test-sohne-medium text-base md:text-lg max-w-[16rem]">
            {post?.title?.substring(0, 50)}{post?.title?.length > 50 ? '...' : ''}
          </h3>
        </div>
        <div className="space-y-3">
          <p className="text-CharcoalGrey text-xs md:text-sm font-test-sohne-light">
            {description?.substring(0, 100)}
          </p>
          <div className="absolute bottom-5">
            <Link
              to={`/blog/${post?.id}`}
              className="text-GreyCloud text-xs md:text-sm"
            >
              read More...
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageBlog;
