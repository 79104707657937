import React from "react";



const GlossaryCard = ({ card}) => {
  const language = localStorage.getItem("selectedLanguage");

  return (
    <div className="space-y-4 md:space-y-0 md:flex-row md:items-start space-x-8 border-b-[0.1px] border-Chardonnay pb-4 pt-6">
      <div className="space-y-3">
        <div className="flex items-start space-x-4">
          <span className="w-fit text-xs rounded-[4px] bg-FloralWhite border border-Chardonnay py-0.5 px-2 font-test-sohne-semibold text-CharcoalGrey mt-1">
            {card?.standard}
          </span>
          <h3 className="max-w-[16rem] text-base md:text-xl font-test-sohne-medium">
            {language === 'en' ? card?.word_en : language === 'fr' ? card?.word_fr || card?.word : null}
          </h3>
        </div>
        <p className="text-sm md:text-base text-CharcoalGrey max-w-xs font-test-sohne-light">
          {language === 'en' ? card?.meaning_en : language === 'fr' ? card?.meaning_fr || card?.meaning : null}
        </p>
      </div>
    </div>
  );
};

export default GlossaryCard;
