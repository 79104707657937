import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { ProgressSpinner } from "primereact/progressspinner";
import { SUBSCRIPTION_VALIDATION } from "../../../utils/fieldValidations";

import { useTranslation } from "react-i18next";

const BlogHeroSection = () => {
  const { t } = useTranslation('blog');
  const [submitting, setSubmitting] = useState(false);

  const handleSubcription = async (values, resetForm) => {
    setSubmitting(true);
    try {
      const response = await axios.post(
        "https://api.smartcomplyapp.com/api/user_mgt/subscribers/",
        values,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      setSubmitting(false);
      if (response?.data?.status) {
        toast.success(
          `${values?.email} successfully subscribed to updates from SmartComply`
        );
        resetForm();
      }
    } catch (err) {
      setSubmitting(false);
      if (err?.response.status === 400) {
        const { data } = err?.response || {};
        toast.error(data.message);
      } else {
        toast.error("Error while processing your request");
      }
    }
  };
  return (
    <div>
      <div className="container mx-auto py-5 md:py-10 mb-6 space-y-12">
        <div className="w-full space-y-4">
          <p className="font-test-sohne-light max-w-xs">
            {t('blogNewsletter')}
          </p>
          <h2 className="flex flex-col text-4xl sm:text-5xl lg:text-[65px] lg:leading-[4.5rem] font-test-sohne-medium">
            <span> {t('blogLatest')}</span>{" "}
            <span>{t('blogCompliance')}</span>
          </h2>
        </div>
        <Formik
          initialValues={{ email: "" }}
          // onSubmit={handleSubcription}
          onSubmit={async (values, { resetForm }) => {
            await handleSubcription(values);
            resetForm();
          }}
          validate={SUBSCRIPTION_VALIDATION}
        >
          {(formik) => {
            const {
              values,
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
            } = formik;
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div className="bg-FloralWhite w-[60%] flex flex-row items-center justify-between border-[0.1px] border-Chardonnay rounded-md px-3">
                      <div className=" w-full flex items-center justify-between space-x-2 py-3">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="w-full bg-transparent focus:outline-0 px-3 text-lg placeholder:text-Nobel"
                          placeholder={t('blogPlaceholder')}
                        />
                        <button type="submit">
                          {submitting ? (
                            <ProgressSpinner
                              style={{ width: "40px", height: "40px" }}
                              className="flex justify-center items-center"
                              strokeWidth="8"
                              fill="white"
                              animationDuration=".5s"
                            />
                          ) : (
                            <span
                              className={`text-3xl ${
                                values?.email !== "" ? "cursor-pointer" : ""
                              }`}
                            >
                              &#x2192;
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                    {errors.email && touched.email ? (
                      <div className="text-[#F85359] text-[12px] pt-1">
                        {errors.email}
                      </div>
                    ) : null}
                    <p>
                      <span className="font-test-sohne-semibold">
                      {t('blogLoop')}{" "}
                      </span>{" "}
                      <span className="font-test-sohne-light text-CharcoalGrey">
                      {t('blogAnnouncements')}
                      </span>
                    </p>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BlogHeroSection;
