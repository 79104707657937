import React from 'react';

const CookiesTable = props => {
    const { data } = props;
    const columnKeys = Object.keys(data?.[0]);
    return (
        <div className="border border-1 border-[#F1F5F9] rounded-[4px] py-6 px-4 mb-6">
            <div className="flex flex-row gap-[24px] bg-[#EEF0FF4D] px-4 py-2">
                {columnKeys?.map((key, index) => (
                    <p
                        key={index}
                        className={`${
                            index === 1 ? 'basis-[70%]' : 'basis-[15%]'
                        } capitalize text-[13px] font-medium text-[#303034]`}
                    >
                        {key}
                    </p>
                ))}
            </div>
            {data?.map((item, index) => (
                <div
                    className={`flex flex-row gap-[24px] px-4 py-2 ${
                        data?.length - 1 === index ? '' : 'border-b border-0 border-solid border-[#F1F5F9]'
                    }}`}
                    key={index}
                >
                    <p className="basis-[15%] text-[12px] font-normal tracking-[0.4px] text-[#0F172A]">{item.name}</p>
                    <p className="basis-[70%] text-[12px] font-normal tracking-[0.4px] text-[#0F172A]">
                        {item?.purpose}
                    </p>
                    <p className="basis-[15%] text-[12px] font-normal tracking-[0.4px] text-[#475569]">
                        {item?.expiration}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default CookiesTable;
