import React from "react";

import { useTranslation } from "react-i18next";

const TermsBody = () => {
  const { t } = useTranslation("terms");
  return (
    <div className="container mx-auto py-12 md:py-24 ">
      <div className="space-y-5 mb-4 md:mb-16">
        <h3 className="text-center text-3xl lg:text-[56px] lg:leading-[4rem] font-test-sohne-medium">
          {t("termsHeading")}
        </h3>
        <div className="space-y-6">
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              {t("platform1.plaformBold")}
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("platform1.platfrormLight")}
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              {t("plarform2.plartform2Bold")}
            </h4>
            <p className="uppercase">{t("plarform2.platform2Light")}</p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("plarform2.platform2Warranties")}
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("plarform2.platform2Warrant")}
            </p>
            <ul className="list-disc text-sm md:text-[15px] text-CharcoalGrey px-4">
              <li>{t("plarform2.platformList.listLegally")}</li>
              <li>{t("plarform2.platformList.listRight")}</li>
              <li>{t("plarform2.platformList.listRead")}</li>
              <li>{t("plarform2.platformList.listAccessing")}</li>
              <li>{t("plarform2.platformList.listEnter")}</li>
            </ul>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("plarform2.platform2Additinally")}
            </p>
            <ul className="list-disc text-sm md:text-[15px] text-CharcoalGrey px-4">
              <li>{t("plarform2.plartformList2.platformList2Operating")}</li>
              <li>{t("plarform2.plartformList2.platformList2Unrestricted")}</li>
              <li>{t("plarform2.plartformList2.platformList2Service")}</li>
              <li>{t("plarform2.plartformList2.platformList2Responsible")}</li>
              <li>{t("plarform2.plartformList2.platformList2Insurance")}</li>
            </ul>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              3. USER CONTRACT
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              You acknowledge and agree that a legally binding contract with
              Smartcomply (the “Service Agreement”) is formed when you agree on
              the terms of a Service. Users do not have authority to enter into
              written or oral — whether implied or express — contracts on behalf
              of Smartcomply.
            </p>
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              4. BILLING AND PAYMENT
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Users of the Smartcomply Platform contract for Services directly
              with the Company. Client, and not Smartcomply, is responsible for
              payment for all Service services through the Smartcomply Platform.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              To help prevent fraud and safeguard User information from the risk
              of unauthorized access, Smartcomply and/or the PSP may validate an
              account before activation and prior to each booking. As part of
              the validation process, temporary charges may be placed on the
              account associated with the User and then refunded within 1-5
              business days. This temporary charge may vary depending on the
              estimated value of the Service and expenses.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Smartcomply reserves the right (but not the obligation) upon
              request from Client, or upon notice of any potential fraud,
              unauthorized charges or other misuse of the Smartcomply Platform,
              to place on hold any Service Payment, (if applicable), or refund
              or provide credits, or arrange for the PSP to do so.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Users of the Smartcomply Platform may be liable for any taxes or
              similar charges (including VAT, if applicable in the country where
              the Service is performed) required to be collected and/or paid on
              the Services and/or fees provided under the Agreement.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              In certain jurisdictions, applicable rules may require that we
              collect or report tax and/or revenue information about you. You
              agree that Smartcomply may issue on your behalf receipts or
              similar documentation to facilitate accurate tax reporting.
            </p>
          </div>
          {/* <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              5. ACCEPTABLE USE
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              The Smartcomply Platform may contain profiles, email systems,
              blogs, message boards, reviews, ratings, Service postings, chat
              areas, news groups, forums, communities and/or other message or
              communication facilities (“Public Areas”) that allow Users to
              communicate with other Users. You may only use such community
              areas to send and receive messages and materials that are relevant
              and proper to the applicable forum. For the safety and integrity
              of the Smartcomply Platform, you should not share your personal
              contact information with other Users.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Without limitation, the Smartcomply Platform may not be used for
              any of the following purposes:
            </p>
            <ul className="list-disc text-sm md:text-[15px] text-CharcoalGrey px-4">
              <li>
                To defame, abuse, harass, stalk, threaten, intimidate,
                misrepresent, mislead or otherwise violate the rights (such as,
                but not limited to, rights of privacy, confidentiality,
                reputation, and publicity) of others, including Users and
                Smartcomply staff;
              </li>
              <li>
                To publish, post, upload, distribute or disseminate any profane,
                defamatory, infringing, obscene or unlawful language, material
                or information;
              </li>
              <li>
                To upload files that contain software or other material that
                violates the intellectual property rights (or rights of privacy
                or publicity) of any User, third party, or Smartcomply;
              </li>
              <li>
                To upload files or scripts such as Trojan horses, corrupt files,
                SQL injections, worms, timebombs, cancelbots or any other files
                or software that may damage Smartcomply or its Users’ computers;
              </li>
              <li>
                To advertise or offer to sell any goods or services for any
                commercial purpose through the Smartcomply Platform which are
                not relevant to the Service;
              </li>
            </ul>
          </div> */}
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              {t("terms5.terms5Bold")}
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms5.terms5Light1")}
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms5.terms5Light2")}
            </p>
            <ul className="list-disc text-sm md:text-[15px] text-CharcoalGrey px-4">
              <li>{t("terms5.terms5List.list1")}</li>
              <li>{t("terms5.terms5List.list2")}</li>
              <li>{t("terms5.terms5List.list3")}</li>
              <li>{t("terms5.terms5List.list4")}</li>
              <li>{t("terms5.terms5List.list5")}</li>
              <li>{t("terms5.terms5List.list6")}</li>
              <li>{t("terms5.terms5List.list7")}</li>
              <li>{t("terms5.terms5List.list8")}</li>
              <li>{t("terms5.terms5List.list9")}</li>
              <li>{t("terms5.terms5List.list10")}</li>
              <li>{t("terms5.terms5List.list11")}</li>
              <li>{t("terms5.terms5List.list12")}</li>
              <li>{t("terms5.terms5List.list13")}</li>
              <li>{t("terms5.terms5List.list14")}</li>
              <li>{t("terms5.terms5List.list15")}</li>
              <li>{t("terms5.terms5List.list16")}</li>
              <li>{t("terms5.terms5List.list17")}</li>
              <li>{t("terms5.terms5List.list18")}</li>
              <li>{t("terms5.terms5List.list19")}</li>
              <li>{t("terms5.terms5List.list20")}</li>
              <li>{t("terms5.terms5List.list21")}</li>
            </ul>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              {t("terms6.terms6Bold")}
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms6.terms6Light")}
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              {t("terms7.terms7Bold")}
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms7.terms7Light1")}
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms7.terms7Light2")}
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms7.terms7Light3")}
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms7.terms7Light4")}
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms7.terms7Light5")}
            </p>
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              {t("terms8.terms8Bold")}
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms8.terms8Light1")}
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms8.terms8Light2")}
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              {t("terms8.terms8Light3")}
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              9. LINKS TO THIRD-PARTY WEBSITES
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              The Smartcomply Platform may contain links (such as hyperlinks) to
              third-party websites. Such links do not constitute endorsement by
              Smartcomply or association with those websites, their content or
              their operators. Such links (including without limitation external
              websites that are framed by the Smartcomply Platform as well as
              any advertisements displayed in connection therewith) are provided
              as an information service, for reference and convenience only.
              Smartcomply does not control any such websites, and is not
              responsible for their availability, accuracy, content,
              advertising, products, or services. It is your responsibility to
              evaluate the content and usefulness of the information obtained
              from other websites. You acknowledge and agree that Smartcomply is
              not involved in the creation or development of third-party
              websites and disclaims any responsibility for third-party
              websites, and cannot be liable for claims arising out of or
              relating to third-party websites. Further, you acknowledge and
              agree that Smartcomply has no obligation to monitor, review, or
              remove links to third-party websites, but reserves the right to
              limit or remove links to third-party websites on the Smartcomply
              Platform at its sole discretion.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              The use of any website controlled, owned or operated by a third
              party is governed by the terms and conditions of use and privacy
              policy for that website. You access such third-party websites at
              your own risk. Smartcomply expressly disclaims any liability
              arising in connection with your use and/or viewing of any websites
              or other material associated with links that may appear on the
              Smartcomply Platform. You hereby agree to hold Smartcomply
              harmless from any liability that may result from the use of links
              that may appear on the Smartcomply Platform.
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              10. INTELLECTUAL PROPERTY RIGHTS
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              All text, graphics, editorial content, data, formatting, graphs,
              designs, HTML, look and feel, photographs, music, sounds, images,
              software, videos, typefaces and other content, including
              Smartcomply designs, trademarks, and logos (collectively
              “Proprietary Material”) that Users see or read through the
              Smartcomply Platform is owned by Smartcomply, excluding User
              Generated Content, which Users hereby grant Smartcomply a license
              to use as set forth above. Proprietary Material is protected in
              all forms, media and technologies now known or hereinafter
              developed. Smartcomply owns all Proprietary Material, as well as
              the coordination, selection, arrangement and enhancement of such
              Proprietary Materials. The Proprietary Material is protected by
              domestic and international laws governing copyright, patents, and
              other proprietary rights. Users may not copy, download, use,
              redesign, reconfigure, or retransmit anything from the Smartcomply
              Platform without SmartComply’s express prior written consent and,
              if applicable, the consent of the holder of the rights to the User
              Generated Content. Any use of such Proprietary Material other than
              as permitted herein is expressly prohibited.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              The service marks and trademarks of Smartcomply, including without
              limitation Smartcomply, and associated logos, are service marks
              owned by Smartcomply. You may not copy or use any of these marks,
              logos or trade names without the express prior written consent of
              the owner.
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              11. CONFIDENTIAL INFORMATION
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              You acknowledge that Confidential Information (as defined below)
              is a valuable, special and unique asset of Smartcomply and agree
              that you will not, for the lifetime of your account on Smartcomply
              plus 10 years thereafter, disclose, transfer, or use (or seek to
              induce others to disclose, transfer or use) any Confidential
              Information for any purpose other than use of the Smartcomply
              Platform in accordance with these Terms of Service. If relevant,
              you may disclose the Confidential Information to your authorized
              employees and agents provided that they are also bound to maintain
              the confidentiality of Confidential Information. You shall use
              best efforts to protect Confidential Information from unauthorized
              disclosure, transfer or use. You shall promptly notify Smartcomply
              in writing of any circumstances that may constitute unauthorized
              disclosure, transfer, or use of Confidential Information. You
              shall return all originals and any copies of any and all materials
              containing Confidential Information to Smartcomply promptly upon
              deactivation of your User account or termination of this Agreement
              for any reason whatsoever.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              The term “Confidential Information” shall mean any and all of
              SmartComply’s trade secrets, confidential and proprietary
              information, and all other information and data of Smartcomply
              that is not generally known to the public or other third parties
              who could derive value, economic or otherwise, from its use or
              disclosure. Confidential Information shall be deemed to include
              technical data, know-how, research, product plans, products,
              services, customers, markets, software, developments, inventions,
              processes, formulas, technology, designs, drawings, engineering,
              hardware configuration information, marketing, finances, strategic
              and other Proprietary Materials and confidential information
              relating to Smartcomply or SmartComply’s business, operations or
              properties, including information about SmartComply’s staff, Users
              or partners, or other business information disclosed or obtained
              directly or indirectly in writing, orally or by drawings or
              observation.
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              12. DISCLAIMER OF WARRANTIES
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              The technology of the Smartcomply platform is provided on an “as
              is” basis without warranties or conditions of any kind, either
              express or implied, including, but not limited to, warranties or
              conditions of merchantability, fitness for a particular purpose,
              good and workmanlike services, and non-infringement. Smartcomply
              makes no warranties or representations about the accuracy or
              completeness of the content provided through the Smartcomply
              platform or the content of any sites linked to the Smartcomply
              platform and assumes no liability or responsibility in contract,
              warranty or in tort for any (i) errors, mistakes, or inaccuracies
              of content, (ii) personal injury or property damage, of any nature
              whatsoever, resulting from your access to and use of the
              Smartcomply platform, (iii) any access to or use of our secure
              servers and/or any and all personal information and/or financial
              information stored therein; and (iv) events beyond our reasonable
              control.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Smartcomply does not warrant, endorse, guarantee or assume
              responsibility for any service advertised or offered by a
              third-party through the Smartcomply platform or any hyperlinked
              website or featured in any banner or other advertising, and
              Smartcomply will not be a party to or in any way be responsible
              for any transaction between you and other Users, or you and
              third-party providers of products or services. Without limiting
              the foregoing, Smartcomply and Affiliates do not warrant that
              access to the Smartcomply Platform will be uninterrupted or that
              the Smartcomply Platform will be error-free; nor do they make any
              warranty as to the results that may be obtained from the use of
              the Smartcomply Platform, or as to the timeliness, accuracy,
              reliability, completeness or content of any Service, service,
              information or materials provided through or in connection with
              the use of the Smartcomply Platform. Smartcomply and Affiliates do
              not warrant that the Smartcomply Platform is free from computer
              viruses, system failures, worms, trojan horses, or other harmful
              components or malfunctions, including during hyperlink to or from
              third-party websites. Smartcomply and Affiliates will implement
              appropriate technical and organizational measures to ensure a
              level of security adapted to the risk for any personal information
              supplied by you.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              A. No Liability
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              You acknowledge and agree that Smartcomply is only willing to
              provide the Smartcomply Platform if you agree to certain
              limitations of our liability to you and third parties. Therefore,
              you agree not to hold Smartcomply and Affiliates, or their
              corporate partners, liable for any claims, demands, damages,
              expenses, losses, governmental obligations, suits, and/or
              controversies of every kind and nature, known and unknown,
              suspected and unsuspected, disclosed and undisclosed, direct,
              indirect, incidental, actual, consequential, economic, special, or
              exemplary, including attorneys fees and costs (collectively,
              “Liabilities” ) that have arisen or may arise, relating to you or
              any other party’s use of or inability to use the Smartcomply
              Platform.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              UNDER NO CIRCUMSTANCES WILL SMARTCOMPLY AND AFFILIATES OR THEIR
              CORPORATE PARTNERS BE LIABLE FOR, AND YOU HEREBY RELEASE
              SMARTCOMPLY AND AFFILIATES AND THEIR CORPORATE PARTNERS FROM ANY
              DIRECT, INDIRECT, INCIDENTAL, ACTUAL, CONSEQUENTIAL, ECONOMIC,
              SPECIAL OR EXEMPLARY DAMAGES (INCLUDING BUT NOT LIMITED TO LOST
              PROFITS, LOSS OF DATA, LOSS OF GOODWILL, SERVICE INTERRUPTION,
              COMPUTER DAMAGE, SYSTEM FAILURE, OR ATTORNEYS FEES AND COSTS)
              ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF OR
              INABILITY TO USE THE SMARTCOMPLY PLATFORM EVEN IF ADVISED OF THE
              POSSIBILITY OF THE SAME. Some jurisdictions do not allow the
              exclusion of certain warranties or limitation of incidental or
              consequential damages; in such cases the above limitations may not
              apply to you in their entirety.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              SMARTCOMPLY AND AFFILIATES EXPRESSLY DISCLAIM ANY LIABILITY THAT
              MAY ARISE BETWEEN USERS OF ITS SMARTCOMPLY PLATFORM. SMARTCOMPLY
              AND AFFILIATES ALSO DO NOT ACCEPT ANY LIABILITY WITH RESPECT TO
              THE QUALITY OR FITNESS OF ANY WORK PERFORMED VIA THE SMARTCOMPLY
              PLATFORM.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              IF, NOTWITHSTANDING THE FOREGOING EXCLUSIONS, IT IS DETERMINED
              THAT SMARTCOMPLY AND AFFILIATES OR THEIR CORPORATE PARTNERS ARE
              LIABLE FOR DAMAGES, IN NO EVENT WILL THE AGGREGATE LIABILITY,
              WHETHER ARISING IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE,
              EXCEED THE TOTAL FEES PAID BY YOU TO SMARTCOMPLY, DURING THE 6
              MONTHS PRIOR TO THE TIME SUCH CLAIM AROSE, TO THE EXTENT PERMITTED
              BY APPLICABLE LAW.
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              13. INDEMNIFICATION
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              You hereby agree to indemnify, defend, and hold harmless
              Smartcomply and Affiliates from and against any and all
              Liabilities incurred in connection with (i) your use or inability
              to use, or your participation on, the Smartcomply Platform; (ii)
              your participation in Services , or your ability or inability to
              perform or obtain the performance of Services or to receive
              payment therefore; (iii) your breach or violation of this
              Agreement; (iv) your violation of any law, or the rights of any
              User or third party; (v) your failure to abide by your
              representations and warranties; Smartcomply reserves the right, in
              its own sole discretion, to assume the exclusive defense and
              control of any matter otherwise subject to your indemnification.
              You will not, in any event, settle any claim or matter without the
              prior written consent of Smartcomply.
            </p>
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              14. DISPUTE RESOLUTION
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              To expedite resolution and reduce the cost of any dispute,
              controversy or claim related to, arising from or regarding your
              use of the Smartcomply Platform, your relationship with
              Smartcomply, Services , or this Agreement (including previous
              versions), ( “Dispute” ), you and Smartcomply agree to first
              attempt to negotiate any Dispute informally for at least 30 days
              before initiating any out of court settlement ( such as mediation
              or arbitration). The decision of an arbitration panel shall be
              final. (except as may be set forth hereafter). Such informal
              negotiations will commence upon written notice. Your address for
              such notices is the one associated with your account, with an
              email copy to the email address you have provided to Smartcomply.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Attention: Smart Comply Ltd
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Email: info@smartcomplyapp.com
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              15. APP STORE/PLAY STORE-SOURCED APPS
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              The following applies to any App accessed through or downloaded
              from the Apple/Google App Store/Play store (an "App Store/Play
              store-Sourced Application"): You acknowledge and agree that (i)
              the Agreement is entered into between you and Smartcomply only,
              and Apple/Google is not a party to this Agreement other than as
              third-party beneficiary as contemplated below, and (ii)
              Smartcomply, not Apple/Google, is solely responsible for the App
              Store/Play store-Sourced Application and content thereof. Your use
              of the App Store/Play store-Sourced Application must comply with
              the App Store/Play store Terms of Service.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              You acknowledge that Apple/Google has no obligation whatsoever to
              furnish any maintenance and support services with respect to the
              App Store/Play store-Sourced Application.
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              16. NO AGENCY; NO EMPLOYMENT
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              No agency, partnership, joint venture, employer-employee or
              franchiser-franchisee relationship is intended or created by this
              Agreement.
            </p>
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              17. GENERAL PROVISIONS
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Failure by Smartcomply to enforce any provision(s) of this
              Agreement will not be construed as a waiver of any provision or
              right. This Agreement constitutes the complete and exclusive
              agreement between you and Smartcomply with respect to its subject
              matter, and supersedes and governs any and all prior agreements or
              communications except as otherwise specified in this Agreement.
              The provisions of this Agreement are intended to be interpreted in
              a manner which makes them valid, legal, and enforceable. In the
              event any provision is found to be partially or wholly invalid,
              illegal or unenforceable, (1) such provision shall be modified or
              restructured to the extent and in the manner necessary to render
              it valid, legal, and enforceable or, (2) if such provision cannot
              be so modified or restructured, it shall be excised from the
              Agreement without affecting the validity, legality or
              enforceability of any of the remaining provisions. You hereby
              acknowledge and agree that we may assign or transfer this
              Agreement without your consent, including but not limited to
              assignments: (i) to a parent or subsidiary, (ii) to an acquirer of
              assets, or (iii) to any other successor or acquirer. Upon the
              effective date of the assignment of the Agreement (a) Smartcomply
              shall be relieved of all rights, obligations and/or liabilities to
              you arising in respect of events postdating the effective date of
              the assignment and, (b) the assignee entity shall replace
              Smartcomply for the performance of this Agreement. This Agreement
              may not be assigned or transferred by you without our prior
              written approval. Any assignment in violation of this Section
              shall be null and void. This Agreement will inure to the benefit
              of Smartcomply, its successors and assigns. All parts of this
              Agreement which by their nature should survive the expiration or
              termination of this Agreement shall continue in full force and
              effect subsequent to and notwithstanding the expiration or
              termination of this Agreement or your use of the Smartcomply
              Platform.
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              18. CHANGES TO THIS AGREEMENT AND THE SMARTCOMPLY PLATFORM
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Smartcomply reserves the right, at its sole and absolute
              discretion, to change, modify, add to, supplement, suspend,
              discontinue, or delete any of the terms and conditions of this
              Agreement (including the Terms of Service, and Privacy Policy) and
              review, improve, modify or discontinue, temporarily or
              permanently, the Smartcomply Platform or any content or
              information through the Smartcomply Platform at any time,
              effective with or without prior notice and without any liability
              to Smartcomply. Smartcomply may also impose limits on certain
              features or restrict your access to part or all of the Smartcomply
              Platform without notice or liability.
            </p>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              Smartcomply will endeavor to notify you of material changes to
              this Agreement by email, but will not be liable for any failure to
              do so. If any future changes to this Agreement are unacceptable to
              you or cause you to no longer be in compliance with this
              Agreement, you must deactivate your account, and immediately stop
              using the Smartcomply Platform. Your continued use of the
              Smartcomply Platform following any revision to this Agreement
              constitutes your complete and irrevocable acceptance of any and
              all such changes, except where prohibited by any laws or
              regulations in your jurisdiction.
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              19. NO RIGHTS OF THIRD PARTIES
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              The provisions of this Agreement are for the sole benefit of the
              Parties and their permitted successors and assigns, and they will
              not be construed as conferring any rights to any third party
              (including any third party beneficiary rights or to give any
              person or entity other than the User any interest, remedy, claim,
              liability, reimbursement, claim of action or any other claim of
              action with respect to or in connection with any agreement or
              provision contained herein or contemplated hereby. None of the
              terms of this Agreement are enforceable by any persons who are not
              a party to this Agreement, provided, however, that a Client’s
              Agent may act in the name of and on behalf of his/her Client.
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              20. NO RIGHTS OF THIRD PARTIES
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              The provisions of this Agreement are for the sole benefit of the
              Parties and their permitted successors and assigns, and they will
              not be construed as conferring any rights to any third party
              (including any third party beneficiary rights or to give any
              person or entity other than the User any interest, remedy, claim,
              liability, reimbursement, claim of action or any other claim of
              action with respect to or in connection with any agreement or
              provision contained herein or contemplated hereby. None of the
              terms of this Agreement are enforceable by any persons who are not
              a party to this Agreement, provided, however, that a Client’s
              Agent may act in the name of and on behalf of his/her Client.
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              21. NOTICES AND CONSENT TO RECEIVE NOTICES ELECTRONICALLY
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              You consent to receive any agreements, notices, disclosures and
              other communications (collectively, “Notices”) to which this
              Agreement refers electronically, including without limitation by
              email or by posting Notices on the Sites. You agree that all
              Notices that we provide to you electronically satisfy any legal
              requirement that such communications be in writing. Unless
              otherwise specified in this Agreement, all Notices under this
              Agreement will be in writing and will be deemed to have been duly
              given when received, if personally delivered or sent by certified
              or registered mail, return receipt requested; when receipt is
              electronically confirmed, if transmitted by facsimile or email; or
              the day it is shown as delivered by the overnight delivery
              service’s tracking information, if sent for next day delivery by a
              recognized overnight delivery service. If you have any questions
              about these Terms of Service or about the Smartcomply Platform,
              please contact us by email at{" "}
              <a href="info@smartcomplayapp.com">info@smartcomplayapp.com</a>
            </p>
          </div>
          <div className="space-y-2">
            <h4 className="font-test-sohne-medium text-base md:text-lg text-opacity-60">
              22. CONSENT TO ELECTRONIC SIGNATURES
            </h4>
            <p className="text-sm md:text-[15px] text-CharcoalGrey">
              By using the Smartcomply Platform, you agree to transact
              electronically through the Smartcomply Platform. You also agree to
              the following: (i) your electronic signature is the legal
              equivalent of your manual signature and has the same legal effect,
              validity and enforceability as a paper-based signature; (ii) your
              use of a keypad, mouse or other device to select an item, button,
              icon or similar act/action, constitutes your signature as if
              actually signed by you in writing; and (iii) no certification
              authority or other third party verification is necessary to
              validate your electronic signature, and the lack of such
              certification or third party verification will not in any way
              affect the enforceability of your electronic signature.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsBody;
