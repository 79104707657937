import React from "react";

import { useTranslation } from "react-i18next";

const FaqHeroSection = () => {
  const { t } = useTranslation('faq');
  return (
    <div>
      <div className="container mx-auto py-5 md:py-10 mb-6 space-y-12">
        <div className="w-full flex flex-col items-center justify-center space-y-4 py-10">
          <p className="font-test-sohne-light max-w-xs">{t('faqSmall')}</p>
          <h2 className="text-center flex flex-col text-4xl sm:text-5xl lg:text-[65px] lg:leading-[4.5rem] font-test-sohne-medium">
            <span> {t('faqBold1')}</span> <span>{t('faqBold2')}</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default FaqHeroSection;
