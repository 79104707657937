import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const RelatedPosts = ({ post }) => {
  const elementRef = useRef();
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    const desc = elementRef?.current?.querySelector("p");
    const img = elementRef?.current?.querySelector("img");
    if (desc) {
      setDescription(desc.textContent);
    }
    if (img) {
      setImage(img?.src);
    }
  }, []);
  return (
    <div
      key={post?.id}
      className="lg:flex items-start lg:w-[500px] md:w-[300px] lg:h-[100%] md:h-[400px] w-[100%] mb-8"
    >
      <div
        dangerouslySetInnerHTML={{ __html: post?.content }}
        ref={elementRef}
        className="hidden"
      />
      <div className="lg:w-[50%] w-[100%]  rounded-2xl">
        <img src={image} className="w-full rounded-2xl" alt="" />
      </div>
      <div className="lg:ml-[2rem] lg:mt-0 mt-3 lg:w-[50%]  w-[100%] cursor-pointer">
        {description?.length > 1 ? (
          <Link to={`/blog/${post?.id}`}>
            <h6 className="font-black">{post?.title}</h6>
            <p className="text-sm md:text-base text-CharcoalGrey max-w-xs font-test-sohne-light">
              {`${description.substring(0, 150)} ${
                description?.length > 150 ? "..." : ""
              }`}
            </p>
          </Link>
        ) : (
          <h6 className="font-black">{post?.title}</h6>
        )}
      </div>
    </div>
  );
};

export default RelatedPosts;
