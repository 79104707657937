import React from "react";
import dedicated_img from "../../../assets/images/dedicated-img.svg";
import vulnerability_scanner from "../../../assets/images/vulnerability_scanner.svg";
import penetration_test from "../../../assets/images/penetration_test.svg";
import automated_scans from "../../../assets/images/automated_scans.svg";
import pci_scan from "../../../assets/images/pci_scan.svg";
import cisco_dashboard from "../../../assets/images/cisco_dashboard.svg";
import checked_icon from "../../../assets/icons/checked_icon.svg";

import { useTranslation } from "react-i18next";

const FeaturesDetails = () => {
  const { t } = useTranslation("features");

  return (
    <div className="relative xl:-top-24 ">
      <div className="container mx-auto">
        <div className="flex flex-col items-center space-y-4 md:flex-row">
          <div className="w-full md:w-1/2">
            <img src={dedicated_img} className="relative xl:-left-16" alt="" />
          </div>
          <div className="w-full md:w-1/2 ">
            <div className="space-y-4 md:space-y-8 relative -top-10">
              <h3 className="text-2xl md:text-4xl font-test-sohne-semibold">
                {t("featuresSupport.supportBold")}
              </h3>
              <p className="text-Boulder text-base md:text-xl">
                {t("featuresSupport.supportLight")}
              </p>
              <div className="space-y-3">
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("featuresSupport.featuresList.gathering")}
                  </p>
                </div>
                <hr className="border-t border-AshGrey" />
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("featuresSupport.featuresList.businessNew")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:space-x-20 xl:space-x-24">
          <div className="w-full md:w-1/2">
            <img
              src={vulnerability_scanner}
              className="relative xl:-left-16"
              alt=""
            />
            <div className="space-y-4 md:space-y-8 relative -top-10">
              <h3 className="text-2xl md:text-4xl font-test-sohne-medium flex flex-col">
                <span>{t("vulnerability.vulnerabilityBold")}</span>{" "}
                <span>{t("vulnerability.vulnerabilityBold2")}</span>
              </h3>
              <p className="text-Boulder text-base md:text-xl xl:max-w-sm">
                {t("vulnerability.vulnerabilityLight")}
              </p>
              <div className="space-y-3">
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("vulnerability.vulnerabilityList.verify")}
                  </p>
                </div>
                <hr className="border-t border-AshGrey" />
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("vulnerability.vulnerabilityList.require")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 ">
            <img
              src={penetration_test}
              className="relative xl:-left-16"
              alt=""
            />
            <div className="space-y-4 md:space-y-8 relative -top-10">
              <h3 className="text-2xl md:text-4xl font-test-sohne-medium flex flex-col">
                <span>{t("penetration.pentestBold")}</span>{" "}
                <span>{t("penetration.pentestBold2")}</span>
              </h3>
              <p className="text-Boulder text-base md:text-xl">
                {t("penetration.pentestLight")}
              </p>
              <div className="space-y-3">
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("penetration.pentestList.necessary")}
                  </p>
                </div>
                <hr className="border-t border-AshGrey" />
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("penetration.pentestList.automate")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:space-x-20 xl:space-x-24">
          <div className="w-full md:w-1/2">
            <img src={pci_scan} className="relative xl:-left-16" alt="" />
            <div className="space-y-4 md:space-y-8 relative -top-10">
              <h3 className="text-2xl md:text-4xl font-test-sohne-medium">
                {t("pciScans.pciScansBold")}
              </h3>
              <p className="text-Boulder text-base md:text-xl">
                {t("pciScans.pciScansLight")}
              </p>
              <div className="space-y-3">
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("pciScans.pciScansList.servers")}
                  </p>
                </div>
                <hr className="border-t border-AshGrey" />
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("pciScans.pciScansList.scheduled")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 ">
            <img
              src={automated_scans}
              className="relative xl:-left-16"
              alt=""
            />
            <div className="space-y-4 md:space-y-8 relative -top-10">
              <h3 className="text-2xl md:text-4xl font-test-sohne-medium">
                {t("autoScans.autoScansBold")}
              </h3>
              <p className="text-Boulder text-base md:text-xl">
                {t("autoScans.autoScansLight")}
              </p>
              <div className="space-y-3">
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("autoScans.autoScansList.cloud")}
                  </p>
                </div>
                <hr className="border-t border-AshGrey" />
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("autoScans.autoScansList.evidence")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:space-x-20 xl:space-x-24">
          <div className="w-full md:w-1/2">
            <img
              src={cisco_dashboard}
              className="relative xl:-left-16"
              alt=""
            />
          </div>
          <div className="w-full md:w-1/2 ">
            <div className="space-y-4 md:space-y-8 relative -top-10">
              <h3 className="text-2xl md:text-4xl font-test-sohne-medium">
                {t("cisoDash.cisoBold")}
              </h3>
              <p className="text-Boulder text-base md:text-xl">
                {t("cisoDash.cisoLight")}
              </p>
              <div className="space-y-3">
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                    {t("cisoDash.cisoList.detailed")}
                  </p>
                </div>
                <hr className="border-t border-AshGrey" />
                <div className="flex flex-row items-start space-x-3">
                  <img src={checked_icon} className="" alt="" />
                  <p className="text-CharcoalGrey font-test-sohne-light xl:max-w-4xl">
                   {t('cisoDash.cisoList.learn')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesDetails;
