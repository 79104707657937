import React from 'react';

const ContentBody = props => {
    const { title, children, classes } = props;
    return (
        <>
            <h4 className="text-[16px] font-bold tracking-[0.1px] text-[#002C72]">{title}</h4>
            <div
                className={`text-[12px] font-normal tracking-[0.1px] leading-[24px] text-[#5E5E62] pt-4 pb-6 ${classes}`}
            >
                {children}
            </div>
        </>
    );
};

export default ContentBody;
