import React, { useEffect, useState } from "react";
import "./index.css";
import Pages from "./components/pages/Pages";
import ScrollToTop from "./components/sections/commonSection/ScrollToTop";
import { BrowserRouter as Router } from "react-router-dom";
import Cookies from "js-cookie";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Import your i18n configuration
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LanguageSwitcher from "./components/primitive/LanguageSwitcher";
import ReactGA from "react-ga";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
// import ReactPixel from "react-facebook-pixel"; // For tracking custom events.
import CookieModal from "./components/cookie/CookieModal";
import CookieNoticeModal from "./components/cookie/CookieNoticeModal";
import PrivacyNoticeModal from "./components/cookie/PrivacyNoticeModal";

function App() {
  ReactGA.initialize("UA-225361373-1");

  const [privacyNoticeModal, setPrivacyNoticeModal] = useState(false);
  const [cookieNoticeModal, setCookieNoticeModal] = useState(false);
  const [cookieModalOpen, setCookieModalOpen] = useState(false);

  const openPrivacyNoticeModal = () => {
    setPrivacyNoticeModal(true);
  };
  const closePrivacyNoticeModal = () => {
    setPrivacyNoticeModal(false);
  };
  const openCookieNoticeModal = () => {
    setCookieNoticeModal(true);
  };
  const closeCookieNoticeModal = () => {
    setCookieNoticeModal(false);
  };

  // useEffect(() => {
  //   const options = {
  //     autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //     debug: false, // enable logs
  //   };
  //   ReactPixel.init("6679733912069780", options);
  //   ReactPixel.pageView(); // For tracking page view
  //   ReactPixel.track("viewContent"); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-event
  // }, []);

  // useEffect(() => {
  //   const ReactPixel = require("react-facebook-pixel");
  //   ReactPixel.default.init("6679733912069780", null, {
  //     autoConfig: true,
  //     debug: true,
  //   });
  //   ReactPixel.default.pageView();
  //   ReactPixel.default.track("ViewContent");
  // }, []);

  useEffect(() => {
    if (Cookies.get("accepted")) {
      setCookieModalOpen(false);
    } else {
      setCookieModalOpen(true);
    }
  }, []);
  return (
    <Router>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ScrollToTop>
        <I18nextProvider i18n={i18n}>
          <Pages />
        </I18nextProvider>
      </ScrollToTop>
      <Analytics />
      <SpeedInsights />

      <CookieModal
        open={cookieModalOpen}
        onClose={() => setCookieModalOpen(false)}
        openPrivacyNoticeModal={openPrivacyNoticeModal}
        openCookieNoticeModal={openCookieNoticeModal}
      />
      <CookieNoticeModal
        open={cookieNoticeModal}
        onClose={closeCookieNoticeModal}
      />
      <PrivacyNoticeModal
        open={privacyNoticeModal}
        onClose={closePrivacyNoticeModal}
      />
    </Router>
  );
}

export default App;
