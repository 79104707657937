import React from "react";
import pennyTreeLogo from "../../../assets/logos/pennyTreeLogo.svg";
import smile_identify from "../../../assets/logos/smile_identify.svg";
import simplifyLogo from "../../../assets/logos/simplifyLogo.svg";
import crus_logo from "../../../assets/logos/crus_logo.svg";
import swiftendLogo from "../../../assets/logos/swiftendLogo.svg";
import veend_logo from "../../../assets/logos/veend_logo.svg";
import swifcard_logo from "../../../assets/logos/swifcard_logo.svg";
import three_LineLogo from "../../../assets/logos/three_LineLogo.svg";
import appZoneLogo from "../../../assets/logos/appZoneLogo.svg";
import blackcopper_logo from "../../../assets/logos/blackcopper_logo.svg";
import logo_4 from "../../../assets/logos/logo_4.svg";
import logo_5 from "../../../assets/logos/logo_5.svg";
import logo_6 from "../../../assets/logos/logo_6.svg";
import logo_7 from "../../../assets/logos/logo_7.svg";
import logo_8 from "../../../assets/logos/logo_8.svg";
import logo_9 from "../../../assets/logos/logo_9.svg";
import logo_10 from "../../../assets/logos/logo_10.svg";
import logo_11 from "../../../assets/logos/logo_11.svg";
import logo_12 from "../../../assets/logos/logo_12.svg";
import logo_13 from "../../../assets/logos/logo_13.svg";
import logo_14 from "../../../assets/logos/logo_14.svg";
import logo_15 from "../../../assets/logos/logo_15.svg";
import logo_16 from "../../../assets/logos/logo_16.svg";

import flex_finance from "../../../assets/logos/flex_finance.svg";
import hugo from "../../../assets/logos/hugo.svg";
import intron_health from "../../../assets/logos/intron_health.svg";
import lenco from "../../../assets/logos/lenco.svg";
import money_pigeon from "../../../assets/logos/money_pigeon.svg";
import qore from "../../../assets/logos/qore.svg";
import seerbit from "../../../assets/logos/seerbit.svg";
import sendsprint from "../../../assets/logos/sendsprint.svg";
import teflon from "../../../assets/logos/teflon.svg";
import theaccubin from "../../../assets/logos/theaccubin.svg";
import tix from "../../../assets/logos/tix.svg";
import unifiedpayment from "../../../assets/logos/unifiedpayment.svg";
import unitybank from "../../../assets/logos/unitybank.svg";
import wingtipflow from "../../../assets/logos/wingtipflow.svg";

import { useTranslation } from "react-i18next";

const FastGrowingBrands = () => {
  const { t } = useTranslation("home");

  return (
    <div className="relative container mx-auto md:-top-32 pb-8 md:pb-16">
      <hr />
      <div className="py-2 md:py-4">
        <div className="flex flex-col items-center space-y-8 md:space-y-0 md:flex-row">
          <div className="w-full md:w-1/2">
            <h4 className="text-3xl md:text-4xl max-w-sm font-test-sohne-medium">
              {t("rapidBold")}
            </h4>
            <p className="font-test-sohne-light">{t("rapidLight")}</p>
          </div>
          <div className="w-full md:w-1/2 space-y-3 md:space-y-4">
            <div className="flex flex-row items-center w-full sm:w-full overflow-auto scrollbar-hide space-x-2 md:space-x-4 relative">
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_11} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_12} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={pennyTreeLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={smile_identify} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={simplifyLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_13} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_14} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_15} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_16} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_11} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_12} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={pennyTreeLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={smile_identify} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={simplifyLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_13} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_14} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_15} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={logo_16} alt="" />
                </div>
              </div>

              <div className="logo_slide">
                <div>
                  <img className="w-12" src={flex_finance} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-12 scale-75" src={hugo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-12 scale-75" src={intron_health} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={money_pigeon} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-75" src={lenco} alt="" />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center w-full sm:w-full overflow-auto scrollbar-hide space-x-2 md:space-x-4 relative">
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={logo_7} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24 scale-50" src={logo_8} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={logo_9} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={logo_10} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24 scale-75" src={crus_logo} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={swiftendLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24 scale-75" src={veend_logo} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={swifcard_logo} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={logo_7} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24 scale-50" src={logo_8} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={logo_9} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={logo_10} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24 scale-75" src={crus_logo} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={swiftendLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24 scale-75" src={veend_logo} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={swifcard_logo} alt="" />
                </div>
              </div>

              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={teflon} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-12" src={theaccubin} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-10" src={qore} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-24" src={seerbit} alt="" />
                </div>
              </div>
              <div className="logo_slide_reverse">
                <div>
                  <img className="w-12" src={sendsprint} alt="" />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center w-full sm:w-full overflow-auto scrollbar-hide space-x-2 md:space-x-4 relative">
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={logo_4} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-50" src={logo_5} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-50" src={logo_6} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={three_LineLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={appZoneLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={blackcopper_logo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={logo_4} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-50" src={logo_5} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24 scale-50" src={logo_6} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={three_LineLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={appZoneLogo} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-24" src={blackcopper_logo} alt="" />
                </div>
              </div>

              <div className="logo_slide">
                <div>
                  <img className="w-24" src={wingtipflow} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-12" src={tix} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-12" src={unitybank} alt="" />
                </div>
              </div>
              <div className="logo_slide">
                <div>
                  <img className="w-12" src={unifiedpayment} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default FastGrowingBrands;
