import React from "react";
import FrameWorkNdprHeroSectionAnnimation from "../FrameWorkNdprSection/FrameWorkNdprHeroSectionAnnimation";

import { useTranslation } from "react-i18next";

const FrameWorkNdprHeroSection = () => {
  const { t } = useTranslation("ndpr");
  return (
    <div className="py-5 md:py-10 mb-12 md:mb-24">
      <div className="container mx-auto flex flex-col space-y-16 md:space-y-36">
        <div className=" space-y-4 md:space-y-6">
          <h2 className="text-4xl sm:text-5xl lg:text-[65px] lg:leading-[4.5rem] font-test-sohne-medium">
            {t("ndprProtectBold")}
          </h2>
          <p className="font-test-sohne-light">{t("ndprProtectLight")}</p>
        </div>
        <FrameWorkNdprHeroSectionAnnimation />
      </div>
    </div>
  );
};

export default FrameWorkNdprHeroSection;
