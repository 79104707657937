import React from 'react';
import { useTranslation } from 'react-i18next';

const DataCollectionTable = () => {
    const { t } = useTranslation('common');
    return (
        <div className="border border-1 border-[#F1F5F9] rounded-[4px] py-6 px-4 mb-6">
            <div className="flex flex-row gap-[24px] bg-[#EEF0FF4D] px-4 py-2">
                <p className="basis-[50%] capitalize text-[13px] font-medium text-[#303034]">Purpose of Processing</p>
                <p className="basis-[50%] capitalize text-[13px] font-medium text-[#303034]">Lawful Bases</p>
            </div>
            {t('dataCollectionLaw', { returnObjects: true })?.map((item, index) => (
                <div
                    className={`flex flex-row gap-[24px] px-4 py-2 ${
                        t('dataCollectionLaw', { returnObjects: true })?.length - 1 === index
                            ? ''
                            : 'border-b border-0 border-solid border-[#F1F5F9]'
                    }}`}
                    key={index}
                >
                    <div className="basis-[50%] text-[12px] font-normal tracking-[0.4px] text-[#0F172A]">
                        <ul className="ml-3 list-disc">
                            {item.purpose?.map((purpose, index) => (
                                <li key={index}>{purpose}</li>
                            ))}
                        </ul>
                    </div>
                    <p className="basis-[50%] text-[12px] font-normal tracking-[0.4px] text-[#0F172A]">
                        {item?.lawful_bases}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default DataCollectionTable;
