import React from "react";
//core component
import { Helmet } from "react-helmet";

const PageHeader = (props) => {
  const { browserTitle } = props;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{browserTitle}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
    </div>
  );
};

export default PageHeader;
