import React from "react";
import FrameWorkSoc2HeroSectionAnnimation from "../FrameWorkSoc2Section/FrameWorkSoc2HeroSectionAnnimation";

import { useTranslation } from "react-i18next";

const FrameWorkSoc2HeroSection = () => {
  const { t } = useTranslation('soc2');
  return (
    <div>
      <div className="py-5 md:py-10 mb-2">
        <div className="container mx-auto flex flex-col space-y-8 md:space-y-0 md:flex-row">
          <div className="w-full md:w-1/2 space-y-12 md:space-y-32">
            <h2 className="text-4xl sm:text-5xl lg:text-[65px] lg:leading-[4.5rem] font-test-sohne-medium">
              {t('soc2HeroBold')}
            </h2>
            <p className="font-test-sohne-light max-w-md">
             {t('soc2HeroLight')}
            </p>
          </div>
          <FrameWorkSoc2HeroSectionAnnimation />
        </div>
      </div>
    </div>
  );
};

export default FrameWorkSoc2HeroSection;
