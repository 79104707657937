import React from "react";
import FrameWorkPcidssHeroSectionAnnimation from "../FrameWorkPcidssSection/FrameWorkPcidssHeroSectionAnnimation";

import { useTranslation } from "react-i18next";

const FrameWorkPcidssHeroSection = () => {
  const { t } = useTranslation('pcdisFramework');

  return (
    <div>
      <div className="py-5 md:py-10 mb-2">
        <div className="container mx-auto flex flex-col space-y-8 md:space-y-0 md:flex-row">
          <div className="w-full md:w-1/2 space-y-4 md:space-y-6">
            <h2 className="text-4xl sm:text-5xl lg:text-[65px] lg:leading-[4.5rem] font-test-sohne-medium">
              {t('pcdisHero.pcdisBold')}
            </h2>
            <p className="font-test-sohne-light">
            {t('pcdisHero.pcdisLight')}
            </p>
          </div>
          <FrameWorkPcidssHeroSectionAnnimation />
        </div>
      </div>
    </div>
  );
};

export default FrameWorkPcidssHeroSection;
