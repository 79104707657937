import React from "react";
import pcidss_monitoring from "../../../assets/images/pcidss_monitoring.svg";
import metrics_and_measurement from "../../../assets/images/metrics_and_measurement.svg";
import automated_risk_assessment_img from "../../../assets/images/automated_risk_assessment_img.svg";
import { useTranslation } from "react-i18next";

const PcidssBody = () => {
  const { t } = useTranslation("pcdisFramework");
  return (
    <div>
      <div className="container mx-auto flex justify-end">
        <div className="lg:w-[85%]">
          <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:space-x-8 xl:space-x-12">
            <div className="w-full md:w-1/2">
              <img
                src={pcidss_monitoring}
                className="relative xl:-left-16"
                alt=""
              />
              <div className="space-y-4 md:space-y-8 relative -top-10">
                <h3 className="text-2xl md:text-4xl font-test-sohne-medium flex flex-col">
                  <span>{t("pcdisBody.pcdisContinousBold1")} </span>{" "}
                  <span>{t("pcdisBody.pcdisContinousBold2")}</span>
                </h3>
                <p className="text-Boulder text-sm md:text-[15px] font-test-sohne-light">
                  {t("pcdisBody.pcdiscontinousLight")}
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 ">
              <img
                src={metrics_and_measurement}
                className="relative xl:-left-16"
                alt=""
              />
              <div className="space-y-4 md:space-y-8 relative -top-10">
                <h3 className="text-2xl md:text-4xl font-test-sohne-medium flex flex-col">
                  <span> {t("pcdisBody.pcdisMetricsBold1")} </span>{" "}
                  <span>{t("pcdisBody.pcdisMetricsBold2")}</span>
                </h3>
                <p className="text-Boulder text-sm md:text-[15px] font-test-sohne-light">
                  {t("pcdisBody.pcdisMetricsLight")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-Magnolia">
        <div className="container mx-auto flex flex-col space-y-4 items-center lg:flex-row md:justify-start md:items-center py-8">
          <div className="w-full md:w-1/2 space-y-4">
            <h2 className="text-3xl lg:text-[56px] lg:leading-[4rem] font-test-sohne-medium max-w-md">
              {t("pcdisBody.pcdisAutomatedBold")}
            </h2>
            <p className="text-sm md:text-base font-test-sohne-light max-w-md">
              {t("pcdisBody.pcdisAutomatedLight")}
            </p>
          </div>
          <div className="w-full md:w-1/2 py-2 md:py-4 relative lg:-bottom-16">
            <img
              src={automated_risk_assessment_img}
              className="bg-white px-10 pt-6 rounded-2xl"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PcidssBody;
