import React from "react";
import Navbar from "../connected/Navbar";
import Footer from "../connected/Footer";
import TermsBody from "../sections/termsSection/TermsBody";
import PageHeader from "../primitive/PageHeader";

const Terms = () => {
  return (
    <div>
      <PageHeader browserTitle="Terms | Smartcomply" />
      <Navbar />
      <TermsBody />
      <Footer />
    </div>
  );
};

export default Terms;
