// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';

// const LanguageToggleButton = () => {
//   const [isChartActive, setChartActive] = useState(true);
//   const [isTableActive, setTableActive] = useState(false);
//   const { i18n } = useTranslation();

//   const toggleSwitch = (view) => {
//     if (view === 'chart') {
//       setChartActive(true);
//       setTableActive(false);
//       i18n.changeLanguage('fr'); // Switch to French when chart is active
//     } else if (view === 'table') {
//       setChartActive(false);
//       setTableActive(true);
//       i18n.changeLanguage('en'); // Switch to English when table is active
//     }
//   };

//   return (
//     <div className="flex items-center space-x-2">
//       <span className="font-test-sohne-semibold">Fren</span>
//       <div className="toggle-switch">
//         <div
//           className={`switch-state ${isChartActive ? 'chart' : ''}`}
//           onClick={() => toggleSwitch('chart')}
//         >
//           <span className="text-transparent">F</span>
//         </div>
//         <div
//           className={`switch-state ${isTableActive ? 'table' : ''}`}
//           onClick={() => toggleSwitch('table')}
//         >
//           <span className="text-transparent">E</span>
//         </div>
//       </div>
//       <span className="text-SeaMist test-sohne-semibold opacity-20">
//         Eng
//       </span>
//     </div>
//   );
// };

// export default LanguageToggleButton;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const [isFrenchActive, setFrenchActive] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setFrenchActive(savedLanguage === "fr");
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const toggleLanguage = () => {
    setFrenchActive(!isFrenchActive);
    const newLanguage = isFrenchActive ? "en" : "fr";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  return (
    <div className="relative z-40">
      <div className="group inline-block">
        <button className="outline-none focus:outline-none flex items-center">
          <span className={`font-test-sohne-semibold`}>
            {isFrenchActive ? "French" : "English"}
          </span>
          <span className="pt-1">
            <svg
              className="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </span>
        </button>
        <div className="">
          <ul className="pt-4 transform scale-0 group-hover:scale-100 absolute transition duration-150 space-y-2 py-3 ease-in-out origin-top xl:w-fit px-4">
            <div
              className="rounded-lg px-2 py-2 space-y-3 bg-white z-40 min-w-40 cursor-pointer"
              onClick={toggleLanguage}
            >
              {isFrenchActive ? "English" : "French"}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
