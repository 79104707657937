import React from "react";

import { useTranslation } from "react-i18next";

const GlossaryHeroSection = () => {
  const { t } = useTranslation('glossary');
  return (
    <div>
      <div className="container mx-auto py-5 md:pt-10 pb-44 mb-6 space-y-12">
        <div className="w-full space-y-4">
          <h2 className="text-4xl sm:text-5xl lg:text-[65px] lg:leading-[4.5rem] font-test-sohne-medium">
            {t('glossaryBold')}
          </h2>
          <p className="font-test-sohne-light">
          {t('glossaryLight')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GlossaryHeroSection;
