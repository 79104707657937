import React from "react";
import Navbar from "../connected/Navbar";
import Footer from "../connected/Footer";
import BookADemoHeroSection from "../sections/bookADemoSection/BookADemoHeroSection";
import PageHeader from "../primitive/PageHeader";

const BookADemo = () => {
  return (
    <div>
      <PageHeader browserTitle="Book a Demo | Smartcomply" />
      <Navbar />
      <BookADemoHeroSection />
      <Footer />
    </div>
  );
};

export default BookADemo;
