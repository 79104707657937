import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//translations files
import headerEn from "./utils/locales/en/header.json";
import headerFr from "./utils/locales/fr/header.json";
import homeEn from "./utils/locales/en/home.json";
import homeFr from "./utils/locales/fr/home.json";
import featuresEn from "./utils/locales/en/features.json";
import featuresFr from "./utils/locales/fr/features.json";
import isoFrameworkEn from "./utils/locales/en/isoFramework.json";
import isoFrameworkFr from "./utils/locales/fr/isoFramework.json";
import pcdisFrameworkEn from "./utils/locales/en/pcdisFramework.json";
import pcdisFrameworkFr from "./utils/locales/fr/pcdisFramework.json";
import soc2En from "./utils/locales/en/soc2.json";
import soc2Fr from "./utils/locales/fr/soc2.json";
import ndprEn from "./utils/locales/en/ndpr.json";
import ndprFr from "./utils/locales/fr/ndpr.json";
import integrationsEn from "./utils/locales/en/integrations.json";
import integrationsFr from "./utils/locales/fr/integrations.json";
import pricingEn from "./utils/locales/en/pricing.json";
import pricingFr from "./utils/locales/fr/pricing.json";
import blogEn from "./utils/locales/en/blog.json";
import blogFr from "./utils/locales/fr/blog.json";
import faqEn from "./utils/locales/en/faq.json";
import faqFr from "./utils/locales/fr/faq.json";
import glossaryEn from "./utils/locales/en/glossary.json";
import glossaryFr from "./utils/locales/fr/glossary.json";
import aboutEn from "./utils/locales/en/about.json";
import aboutFr from "./utils/locales/fr/about.json";
import termsEn from "./utils/locales/en/terms.json";
import termsFr from "./utils/locales/fr/terms.json";
import privacyEn from "./utils/locales/en/privacy.json";
import privacyFr from "./utils/locales/fr/privacy.json";
import footerEn from "./utils/locales/en/footer.json";
import footerFr from "./utils/locales/fr/footer.json";
import mobileSidebarEn from "./utils/locales/en/mobileSidebar.json";
import mobileSidebarFr from "./utils/locales/fr/mobileSidebar.json";
import bookDemoEn from "./utils/locales/en/bookDemo.json";
import bookDemoFr from "./utils/locales/fr/bookDemo.json";
import commonEn from "./utils/locales/en/common.json";
import commonFr from "./utils/locales/fr/common.json";
import signInEn from "./utils/locales/en/signIn.json";
import signInFr from "./utils/locales/fr/signIn.json";
import signUpEn from "./utils/locales/en/signUp.json";
import signUpFr from "./utils/locales/fr/signUp.json";

const resources = {
  en: {
    header: headerEn,
    home: homeEn,
    features: featuresEn,
    isoFramework: isoFrameworkEn,
    pcdisFramework: pcdisFrameworkEn,
    soc2: soc2En,
    ndpr: ndprEn,
    integrations: integrationsEn,
    pricing: pricingEn,
    blog: blogEn,
    faq: faqEn,
    glossary: glossaryEn,
    about: aboutEn,
    terms: termsEn,
    privacy: privacyEn,
    footer: footerEn,
    mobileSidebar: mobileSidebarEn,
    bookDemo: bookDemoEn,
    common: commonEn,
    signIn: signInEn,
    signUp: signUpEn,
  },
  fr: {
    header: headerFr,
    home: homeFr,
    features: featuresFr,
    isoFramework: isoFrameworkFr,
    pcdisFramework: pcdisFrameworkFr,
    soc2: soc2Fr,
    ndpr: ndprFr,
    integrations: integrationsFr,
    pricing: pricingFr,
    blog: blogFr,
    faq: faqFr,
    glossary: glossaryFr,
    about: aboutFr,
    terms: termsFr,
    privacy: privacyFr,
    footer: footerFr,
    mobileSidebar: mobileSidebarFr,
    bookDemo: bookDemoFr,
    common: commonFr,
    signIn: signInFr,
    signUp: signUpFr,
  },
};

i18n.use(initReactI18next).init({
  lng: "en", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: resources,
});

export default i18n;
