import React from "react";
import doc_prep_tools from "../../../assets/images/doc_prep_tools.svg";
import risk_assessment from "../../../assets/images/risk_assessment.svg";
import effective_risk_management from "../../../assets/images/effective_risk_management.svg";

import { useTranslation } from "react-i18next";

const Soc2Body = () => {
  const { t } = useTranslation("soc2");
  return (
    <div>
      <div className="container mx-auto flex justify-end">
        <div className="lg:w-[85%]">
          <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:space-x-8 xl:space-x-12">
            <div className="w-full md:w-1/2">
              <img
                src={doc_prep_tools}
                className="relative xl:-left-16"
                alt=""
              />
              <div className="space-y-4 md:space-y-8 relative -top-10">
                <h3 className="text-2xl md:text-4xl font-test-sohne-medium flex flex-col">
                  <span>{t("soc2Achieve")}</span>{" "}
                  <span>{t("soc2Compliance")}</span>
                </h3>
                <p className="text-Boulder text-sm md:text-[15px] font-test-sohne-light">
                  {t("soc2AchieveLight")}
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 ">
              <img
                src={risk_assessment}
                className="relative xl:-left-16"
                alt=""
              />
              <div className="space-y-4 md:space-y-8 relative -top-10">
                <h3 className="text-2xl md:text-4xl font-test-sohne-medium flex flex-col">
                  <span>{t("soc2Smart")} </span> <span>{t("soc2Mapping")}</span>
                </h3>
                <p className="text-Boulder text-sm md:text-[15px] font-test-sohne-light">
                  {t("soc2SmartLight")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-Magnolia">
        <div className="container mx-auto flex flex-col space-y-4 items-center lg:flex-row md:justify-start md:items-center py-8">
          <div className="w-full md:w-1/2 space-y-4">
            <h2 className="text-3xl lg:text-[56px] lg:leading-[4rem] font-test-sohne-medium max-w-sm">
              {t("soc2Effective")}
            </h2>
            <p className="text-sm md:text-base font-test-sohne-light max-w-md">
              {t("soc2EffectiveLight")}
            </p>
          </div>
          <div className="w-full md:w-1/2 py-2 md:py-4 relative lg:-bottom-16">
            <img
              src={effective_risk_management}
              className="bg-white px-10 pt-6 rounded-2xl"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Soc2Body;
