import React, { useEffect, useRef, useState } from "react";
import date_icon from "../../../assets/icons/date_icon.svg";
import CustomLink from "../CustomLink";
import initial_black_icon from "../../../assets/icons/inital_black_arrow.svg";
import hover_black_icon from "../../../assets/icons/hover_black_arrow.svg";
import moment from "moment";

const BlogCard = ({ labels, blog_title, id, date_posted, heading }) => {
  const elementRef = useRef();
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    const desc = elementRef?.current?.querySelector("p");
    const img = elementRef?.current?.querySelector("img");
    if (desc) {
      setDescription(desc.textContent);
    }
    if (img) {
      setImage(img?.src);
    }
  }, []);
  return (
    <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-start space-x-8">
      <div className="w-64 border border-VanillaIce rounded-2xl">
        <img src={image} className="w-full rounded" alt="" />
      </div>
      <div className="space-y-3">
        <div className="flex items-center space-x-2">
          <>
            {labels?.map((label, index) => (
              <span
                key={index}
                className="w-fit text-[10px] rounded-[4px] bg-FloralWhite border border-Chardonnay py-0.5 px-1 font-test-sohne-semibold text-CharcoalGrey uppercase"
              >
                {label}
              </span>
            ))}
          </>
          <img src={date_icon} alt="" />
          <span className="text-xs font-test-sohne-light">
            {moment(date_posted).format("MMMM do, YYYY")}
          </span>
        </div>
        <h3 className="max-w-sm text-base md:text-xl font-test-sohne-medium">
          {blog_title}
        </h3>
        <p className="text-sm md:text-base text-CharcoalGrey max-w-xs font-test-sohne-light">
          {`${description.substring(0, 70)}${
            description?.length > 1 ? "..." : ""
          }`}
        </p>
        <div
          dangerouslySetInnerHTML={{ __html: heading }}
          ref={elementRef}
          className="hidden"
        />{" "}
        {/* {description?.length > 0 && ( */}
        <CustomLink
          to={`/blog/${id}`}
          title="Read More"
          className="bg-PaleViolet text-sm md:text-base text-Ebony"
          inital_arrow={initial_black_icon}
          hover_arrow={hover_black_icon}
        />
        {/* )} */}
      </div>
    </div>
  );
};

export default BlogCard;
