import React from "react";

import { useTranslation } from "react-i18next";

const PrivacyBody = () => {
  const { t } = useTranslation("privacy");
  return (
    <div className="container mx-auto py-12 md:py-24 ">
      <div className="space-y-5 mb-4 md:mb-16">
        <h3 className="text-center text-3xl lg:text-[56px] lg:leading-[4rem] font-test-sohne-medium">
          {t("privacyHeading")}
        </h3>
        <p className="font-test-sohne-semibold text-xs">{t("lastUpdated")}</p>
      </div>
      <div className="space-y-8">
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("privacyLight1")}
          <a href="https://smartcomply.com/">{t("privacyLight2")}</a>{" "}
          {t("privacyLight3")}
          <a href=" https://app.smartcomply.com/"> {t("privacyLight4")}</a>{" "}
          {t("privacyLight5")}
        </p>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("privacyOverview.overviewBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("privacyOverview.overviewLight")}
        </p>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("whoWeAre.whoWeAreBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("whoWeAre.whoWeAreLight")}
        </p>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("personalInfo.personalInfoBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("personalInfo.personalInfoLight")}
        </p>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("cookies.cookiesBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("cookies.cookiesLight")}
        </p>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("legalBases.legalBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("legalBases.legalLight1")}
        </p>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("legalBases.legalLight2")}
        </p>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("legalBases.legalLight3")}
        </p>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("legalBases.legalLight4")}
        </p>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("purpose.purposeBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("purpose.purposeLight")}
        </p>
        <div className="border border-[#eee] p-3">
          <div className="w-full flex border-b border-b-[#eee]">
            <div className="w-full">
              <p className="w-full bg-PaleTurquoise px-4 py-3">
                {t("purpose.purposeTable.tableProcessingBold")}
              </p>
              <ul class="list-disc px-7 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableProcessingLight1")}
                </li>
                <li className="text-sm">
                  {t("purpose.purposeTable.tableProcessingLight2")}
                </li>
                <li className="text-sm">
                  {t("purpose.purposeTable.tableProcessingLight3")}
                </li>
              </ul>
            </div>
            <div className="w-full">
              <p className="w-full bg-PaleTurquoise px-4 py-3">
                {t("purpose.purposeTable.tableLawfulBold")}
              </p>
              <ul class="px-4 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableLawfulLight1")}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex border-b border-b-[#eee]">
            <div className="w-full">
              <ul class="list-disc px-7 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableProcessingLight4")}
                </li>
              </ul>
            </div>
            <div className="w-full">
              <ul class="px-4 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableLawfulLight2")}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex border-b border-b-[#eee]">
            <div className="w-full">
              <ul class="list-disc px-7 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableProcessingLight5")}
                </li>
                <li className="text-sm">
                  {t("purpose.purposeTable.tableProcessingLight6")}
                </li>
              </ul>
            </div>
            <div className="w-full">
              <ul class="px-4 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableLawfulLight3")}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex border-b border-b-[#eee]">
            <div className="w-full">
              <ul class="list-disc px-7 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableProcessingLight7")}
                </li>
              </ul>
            </div>
            <div className="w-full">
              <ul class="px-4 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableLawfulLight4")}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex border-b border-b-[#eee]">
            <div className="w-full">
              <ul class="list-disc px-7 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableProcessingLight8")}
                </li>
              </ul>
            </div>
            <div className="w-full">
              <ul class="px-4 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableLawfulLight5")}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex border-b border-b-[#eee]">
            <div className="w-full">
              <ul class="list-disc px-7 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableProcessingLight9")}
                </li>
              </ul>
            </div>
            <div className="w-full">
              <ul class="px-4 py-2">
                <li className="text-sm">
                  {t("purpose.purposeTable.tableLawfulLight6")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
            {t("protectionInfo.protectionBold")}
          </h4>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("protectionInfo.protectionLight")}
          </p>
          <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
            {t("disclosure.disclosureBold")}
          </h4>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("disclosure.disclosureLight1")}
          </p>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("disclosure.disclosureLight2")}
          </p>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("disclosure.disclosureLight3")}
          </p>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("disclosure.disclosureLight4")}
          </p>
          <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
            {t("yourRights.rightsBold")}
          </h4>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("yourRights.rightLight1")}
          </p>
          <ul className="list-disc px-4">
            <li>{t("yourRights.rightLight2")}</li>
            <li>{t("yourRights.rightLight3")}</li>
            <li>{t("yourRights.rightLight4")}</li>
            <li> {t("yourRights.rightLight5")}</li>
            <li>{t("yourRights.rightLight6")}</li>
          </ul>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("yourRights.rightsLight7")}
          </p>
        </div>
        <div>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("yourRights.rightsLight8")}
          </p>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("yourRights.rightsLight9")}
          </p>
          <a href="Email: ope@smartcomplyapp.com">
            {t("yourRights.rightsLight10")}
          </a>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("yourRights.rightsLight11")}
          </p>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("yourRights.rightsLight12")}
          </p>
        </div>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("retention.retentionBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("retention.retentionLight")}
        </p>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("transfer.transferBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("transfer.transferLight1")}
        </p>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("transfer.transferLight2")}
        </p>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("transfer.transferLight3")}
        </p>
        <ul className="list-disc px-4">
          <li> {t("transfer.transferLight4")}</li>
          <li>{t("transfer.transferLight5")}</li>
          <li>{t("transfer.transferLight6")}</li>
          <li> {t("transfer.transferLight7")}</li>
          <li>{t("transfer.transferLight8")}</li>
          <li>{t("transfer.transferLight9")}</li>
        </ul>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("transfer.transferLight10")}
        </p>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("complaints.complaintsBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("complaints.complaintsLight1")}
        </p>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("complaints.complaintsLight2")}
        </p>
        <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
          {t("changes.chnagesBold")}
        </h4>
        <p className="text-sm md:text-[15px] text-CharcoalGrey">
          {t("changes.changesLight")}
        </p>
        <div className="space-y-2 lg:max-w-md">
          <h4 className="font-test-sohne-medium text-base md:text-xl text-opacity-60">
            {t("contactUs.contactUsBold")}
          </h4>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("contactUs.contsctUsLight1")}
          </p>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("contactUs.contsctUsLight2")}
          </p>
          <p className="text-sm md:text-[15px] text-CharcoalGrey">
            {t("contactUs.contsctUsLight3")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyBody;
