import React, { useEffect, useState } from "react";
import Tab from "../../controls/Tab";
import BlogCard from "../../primitive/cards/BlogCard";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";

import { useTranslation } from "react-i18next";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const BLOG_ID = process.env.REACT_APP_GOOGLE_BLOG_ID;

const BlogBody = () => {
  const { t } = useTranslation("blog");
  const tabLists = [
    { tabName: t("blogTablists.tabAll"), id: "all" },
    { tabName: t("blogTablists.tabCompliance"), id: "compliance" },
    { tabName: t("blogTablists.tabCybersecurity"), id: "cybersecurity" },
    { tabName: t("blogTablists.tabProductUpdates"), id: "product updates" },
    { tabName: t("blogTablists.tabCompanyNews"), id: "company news" },
    { tabName: t("blogTablists.tabAi"), id: "ai" },
  ];
  const [activeTab, setActiveTab] = useState(tabLists[0].id);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID}/posts?key=${API_KEY}`
      );
      setLoading(false);
      const modified_posts = response.data.items?.map((post) => {
        return {
          author: post?.author,
          blog: post?.blog,
          content: post?.content,
          etag: post?.etag,
          id: post?.id,
          kind: post?.kind,
          published: post?.published,
          selfLink: post?.selfLink,
          title: post?.title,
          updated: post?.updated,
          url: post?.url,
          labels: post?.labels?.map((label) => label?.toLowerCase()),
        };
      });
      const filtered_post = modified_posts?.filter((post) =>
        post.labels?.includes(activeTab)
      );
      setPosts(activeTab === "all" ? response.data.items : filtered_post);
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    getAllPosts();
  }, [activeTab]);
  return (
    <div className="container mx-auto space-y-10">
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-20 pb-10 md:pb-24">
        <div className="w-full md:w-[30%]">
          {tabLists.map((tab) => (
            <Tab
              key={tab.id}
              tabName={tab.tabName}
              active={tab.id === activeTab}
              onClick={() => setActiveTab(tab.id)}
            />
          ))}
        </div>
        <div className="w-full md:w-[70%]">
          {loading ? (
            <ProgressSpinner
              style={{ width: "40px", height: "40px" }}
              className="flex justify-center items-center lg:ml-[25rem] md:ml-[10rem] mt-[10rem]"
              strokeWidth="8"
              fill="white"
              animationDuration=".5s"
            />
          ) : (
            <>
              {posts?.length > 1 ? (
                <div className="space-y-7">
                  {activeTab === "all" && (
                    <>
                      {posts.map((card, index) => (
                        <BlogCard
                          key={index}
                          date_posted={card?.published}
                          heading={card?.content}
                          link={card?.url}
                          blog_post_image={card?.author.image.url}
                          category={card?.category}
                          blog_title={card?.title}
                          id={card?.id}
                          labels={card.labels}
                        />
                      ))}
                    </>
                  )}
                  {activeTab === "ai" && (
                    <>
                      {posts.map((card, index) => (
                        <BlogCard
                          key={index}
                          date_posted={card?.published}
                          heading={card?.content}
                          link={card?.url}
                          blog_post_image={card?.author.image.url}
                          category={card?.category}
                          blog_title={card?.title}
                          id={card?.id}
                          labels={card.labels}
                        />
                      ))}
                    </>
                  )}
                  {activeTab === "compliance" && (
                    <>
                      {posts.map((card, index) => (
                        <BlogCard
                          key={index}
                          date_posted={card?.published}
                          heading={card?.content}
                          link={card?.url}
                          blog_post_image={card?.author.image.url}
                          category={card?.category}
                          blog_title={card?.title}
                          id={card?.id}
                          labels={card.labels}
                        />
                      ))}
                    </>
                  )}
                  {activeTab === "cybersecurity" && (
                    <>
                      {posts.map((card, index) => (
                        <BlogCard
                          key={index}
                          date_posted={card?.published}
                          heading={card?.content}
                          link={card?.url}
                          blog_post_image={card?.author.image.url}
                          category={card?.category}
                          blog_title={card?.title}
                          id={card?.id}
                          labels={card.labels}
                        />
                      ))}
                    </>
                  )}
                  {activeTab === "product updates" && (
                    <>
                      {posts.map((card, index) => (
                        <BlogCard
                          key={index}
                          date_posted={card?.published}
                          heading={card?.content}
                          link={card?.url}
                          blog_post_image={card?.author.image.url}
                          category={card?.category}
                          blog_title={card?.title}
                          id={card?.id}
                          labels={card.labels}
                        />
                      ))}
                    </>
                  )}
                  {activeTab === "company news" && (
                    <>
                      {posts.map((card, index) => (
                        <BlogCard
                          key={index}
                          date_posted={card?.published}
                          heading={card?.content}
                          link={card?.url}
                          blog_post_image={card?.author.image.url}
                          category={card?.category}
                          blog_title={card?.title}
                          id={card?.id}
                          labels={card.labels}
                        />
                      ))}
                    </>
                  )}
                </div>
              ) : (
                <h4 className="flex justify-center items-center ml-[10rem] mt-[10rem] capitalize">
                  {activeTab === "all"
                    ? "No blog post found"
                    : `no ${activeTab?.replace(/_/g, " ")} blog post found`}
                </h4>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default BlogBody;
