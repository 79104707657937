import React from "react";
import { useTranslation } from "react-i18next";

const EasyQuickAndEfficient = () => {
  const { t } = useTranslation("home");
  return (
    <div className="py-4 md:pt-10">
      <div className="container mx-auto space-y-1.5 md:space-y-3 mb-10">
        <h3 className="flex flex-col text-3xl lg:text-[56px] lg:leading-[4rem] font-test-sohne-medium">
          {t("easyQuick.easyBold")}
        </h3>
        <p className="text-sm md:text-base text-CharcoalGrey">
          {t("easyQuick.easyLight")}
        </p>
      </div>
      <div>
        <div>
          <div className="container mx-auto py-4 lg:py-0">
            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-x-2 gap-y-4 md:gap-y-0">
              <div className="space-y-2 md:space-y-3 h-[180px]">
                <h4 className="max-w-[16rem] text-SunsetOrange font-test-sohne-medium text-base md:text-xl text-opacity-60">
                  {t("easyQuick.easyCards.aScan.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.aScan.text")}
                </p>
              </div>
              <div className="space-y-2 md:space-y-3 lg:border-l lg:border-l-Seashell lg:pl-8 h-[180px]">
                <h4 className="max-w-[16rem] text-SunsetOrange font-test-sohne-medium text-base md:text-xl text-opacity-60">
                  {t("easyQuick.easyCards.pTest.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.pTest.text")}
                </p>
              </div>
              <div className="space-y-2 md:space-y-3 lg:border-l lg:border-l-Seashell lg:pl-8 h-[180px]">
                <h4 className="max-w-[16rem] text-SunsetOrange font-test-sohne-medium text-base md:text-xl text-opacity-60">
                  {t("easyQuick.easyCards.rManage.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.rManage.text")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-Magnolia">
          <div className="container mx-auto py-4 lg:py-0">
            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-x-2 gap-y-4 md:gap-y-0">
              <div className="space-y-2 md:space-y-3 md:py-8 h-[180px]">
                <h4 className="max-w-[16rem] text-LavendarIndigo font-test-sohne-medium text-base md:text-xl text-opacity-60">
                  {t("easyQuick.easyCards.vScanner.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.vScanner.text")}
                </p>
              </div>
              <div className="space-y-2 md:space-y-3 lg:border-l lg:border-l-Seashell lg:pl-8 md:py-8 h-[180px]">
                <h4 className="max-w-[16rem] text-LavendarIndigo font-test-sohne-medium text-base md:text-xl text-opacity-60">
                  {t("easyQuick.easyCards.fIntegrate.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.fIntegrate.text")}
                </p>
              </div>
              <div className="space-y-2 md:space-y-3 lg:border-l lg:border-l-Seashell lg:pl-8 md:py-8 h-[180px]">
                <h4 className="max-w-[16rem] text-LavendarIndigo font-test-sohne-medium text-base md:text-xl text-opacity-60">
                  {t("easyQuick.easyCards.sqrBot.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.sqrBot.text")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container mx-auto py-4 lg:py-0">
            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-x-2 gap-y-4 md:gap-y-0">
              <div className="space-y-2 md:space-y-3 md:py-8 h-[180px]">
                <h4 className="max-w-[16rem] text-Chardonnay font-test-sohne-medium text-base md:text-xl">
                  {t("easyQuick.easyCards.ciscoDash.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.sqrBot.text")}
                </p>
              </div>
              <div className="space-y-2 md:space-y-3 lg:border-l lg:border-l-Seashell lg:pl-8 lg:py-8 h-[180px]">
                <h4 className="max-w-[16rem] text-Chardonnay font-test-sohne-medium text-base md:text-xl">
                  {t("easyQuick.easyCards.pciScans.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.pciScans.text")}
                </p>
              </div>
              <div className="space-y-2 md:space-y-3 lg:border-l lg:border-l-Seashell lg:pl-8 lg:py-8 h-[180px]">
                <h4 className="max-w-[16rem] text-Chardonnay font-test-sohne-medium text-base md:text-xl">
                  Ï{t("easyQuick.easyCards.aInventory.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.aInventory.text")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-DesertStorm">
          <div className="container mx-auto py-4 lg:py-0">
            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-x-2 gap-y-4 md:gap-y-0">
              <div className="space-y-2 md:space-y-3 md:py-8 h-[180px]">
                <h4 className="max-w-[16rem] text-MountainMist font-test-sohne-medium text-base md:text-xl">
                  {t("easyQuick.easyCards.pManage.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.pManage.text")}
                </p>
              </div>
              <div className="space-y-2 md:space-y-3 md:border-l md:border-l-Seashell lg:pl-8 md:py-8 h-[180px]">
                <h4 className="max-w-[16rem] text-MountainMist font-test-sohne-medium text-base md:text-xl">
                  {t("easyQuick.easyCards.vrAssessment.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.vrAssessment.text")}
                </p>
              </div>
              <div className="space-y-2 md:space-y-3 md:border-l md:border-l-Seashell lg:pl-8 md:py-8 h-[180px]">
                <h4 className="max-w-[16rem] text-MountainMist font-test-sohne-medium text-base md:text-xl">
                  {t("easyQuick.easyCards.sqrBot2.title")}
                </h4>
                <p className="max-w-[16rem] text-sm md:text-[15px] text-CharcoalGrey">
                  {t("easyQuick.easyCards.sqrBot2.text")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EasyQuickAndEfficient;
