import React from "react";
import { Dialog } from "primereact/dialog";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import ContentBody from "./ContentBody";
import CookiesTable from "./CookiesTable";
import { useTranslation } from "react-i18next";

const CookieNoticeModal = (props) => {
  const { open, onClose } = props;

  const { t } = useTranslation("common");

  return (
    <Dialog
      header={
        <div className="flex flex-row items-center gap-3">
          <button className="bg-[#EEF0FF4D] text-[#77777A] rounded-full p-2">
            <HiOutlineArrowNarrowLeft />
          </button>
          <p>{t("cookieNoticeModal.header")}</p>
        </div>
      }
      visible={open}
      style={{
        width: "707px",
        position: "absolute !important",
        bottom: "1% !important",
        padding: "2rem",
      }}
      onHide={onClose}
      draggable={false}
      headerStyle={{
        borderRadius: "none",
        borderTopLeftRadius: "0 !important",
        borderTopRightRadius: "0 !important",
      }}
    >
      <h4 className="text-[12px] font-bold text-[#46464A] tracking-[0.1px] mb-6">
        {t("cookieNoticeModal.date")}
      </h4>
      <ContentBody title={t("cookieNoticeModal.sectionOne.header")}>
        <p
          className="mb-3"
          dangerouslySetInnerHTML={{
            __html: t("cookieNoticeModal.sectionOne.content"),
          }}
        />
      </ContentBody>
      <ContentBody title={t("cookieNoticeModal.sectionTwo.header")}>
        {t("cookieNoticeModal.sectionTwo.content")}
      </ContentBody>
      <ContentBody title={t("cookieNoticeModal.sectionThree.header")}>
        <p
          className="mb-3"
          dangerouslySetInnerHTML={{
            __html: t("cookieNoticeModal.sectionThree.content.itemOne"),
          }}
        />
        <p
          className="mb-3"
          dangerouslySetInnerHTML={{
            __html: t("cookieNoticeModal.sectionThree.content.itemTwo"),
          }}
        />
        <p
          className="mb-3"
          dangerouslySetInnerHTML={{
            __html: t("cookieNoticeModal.sectionThree.content.itemThree"),
          }}
        />
        <p>{t("cookieNoticeModal.sectionThree.content.last")}</p>
      </ContentBody>
      <ContentBody title={t("cookieNoticeModal.sectionFour.header")}>
        <p className="mb-4">{t("cookieNoticeModal.sectionFour.content")}</p>

        <CookiesTable data={t("necessaryCookies", { returnObjects: true })} />
      </ContentBody>
      <ContentBody title={t("cookieNoticeModal.sectionFive.header")}>
        <p className="mb-4">{t("cookieNoticeModal.sectionFive.content")}</p>

        <CookiesTable data={t("preferenceCookies", { returnObjects: true })} />
      </ContentBody>
      <ContentBody title={t("cookieNoticeModal.sectionSix.header")}>
        <p className="mb-4">{t("cookieNoticeModal.sectionSix.content")}</p>

        <CookiesTable data={t("statisticalCookies", { returnObjects: true })} />
      </ContentBody>
      <ContentBody title={t("cookieNoticeModal.sectionSeven.header")}>
        <p className="mb-4">{t("cookieNoticeModal.sectionSeven.content")}</p>

        <CookiesTable data={t("marketingCookies", { returnObjects: true })} />
      </ContentBody>
      <ContentBody title={t("cookieNoticeModal.sectionEight.header")}>
        <p>{t("cookieNoticeModal.sectionEight.content-part1")}</p>
        <ul className="ml-3 list-disc mb-4">
          <li className="underline">
            <a
              href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Firefox
            </a>
          </li>
          <li className="underline">
            <a
              href="https://privacy.microsoft.com/en-us/privacystatement"
              target="_blank"
              rel="noreferrer"
            >
              Internet explorer
            </a>
          </li>
          <li className="underline">
            <a
              href="https://support.google.com/chrome/answer/95647?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              Google chrome
            </a>
          </li>
        </ul>
        <p
          dangerouslySetInnerHTML={{
            __html: t("cookieNoticeModal.sectionEight.content-part2"),
          }}
        />

        <p
          dangerouslySetInnerHTML={{
            __html: t("cookieNoticeModal.sectionEight.content-part3"),
          }}
        />
      </ContentBody>
    </Dialog>
  );
};

export default CookieNoticeModal;
