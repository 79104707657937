import React from "react";
import CustomLink from "../../primitive/CustomLink";
import BookDemoIconsTransition from "./BookDemoIconsTransition";
import HomeHeroSectionAnimationImg from "./HomeHeroSectionAnimationImg";
import initial_white_icon from "../../../assets/icons/inital_white_arrow.svg";
import hover_white_icon from "../../../assets/icons/hover_white_arrow.svg";
import { useTranslation } from "react-i18next";

const HomeHeroSection = () => {
  const { t } = useTranslation("home");

  return (
    <div className="container mx-auto space-y-10">
      <div className="flex flex-col items-center space-y-8 md:space-y-0 md:flex-row md:justify-between md:space-x-10 py-4">
        <div className="w-full md:w-1/2 space-y-8 md:space-y-6">
          <h2 className="text-4xl sm:text-5xl md:text-4xl lg:text-[62px] lg:leading-[4.5rem] font-test-sohne-medium">
            {t("hero.heroBold")}
          </h2>
          <p>{t("hero.heroLight")}</p>
          <div className="w-full flex items-center space-x-4 md:space-x-2">
            <CustomLink
              to="/book-a-demo"
              title={t("hero.heroBtn")}
              className="bg-Ebony text-sm md:text-base text-white hover:bg-ShipGrey"
              inital_arrow={initial_white_icon}
              hover_arrow={hover_white_icon}
            />
            <div className="hidden xl:block w-20 border-b border-AshGrey"></div>
            <BookDemoIconsTransition />
          </div>
        </div>
        <div className="w-full md:w-1/2 flex justify-center md:justify-end">
          <HomeHeroSectionAnimationImg />
        </div>
      </div>
    </div>
  );
};

export default HomeHeroSection;
