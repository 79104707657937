import React from "react";
import { Dialog } from "primereact/dialog";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import emojis_cookie from "../../assets/icons/emojis_cookie.svg";
const CookieModal = (props) => {
  const { open, onClose, openCookieNoticeModal } = props;

  const { t } = useTranslation("common");

  const footerContent = (
    <>
      <button
        onClick={() => {
          Cookies.set("accepted", "true", { expires: 90 });
          onClose();
        }}
      >
        {t("cookieModal.cta1")}
      </button>
      <button onClick={openCookieNoticeModal}> {t("cookieModal.cta2")}</button>
    </>
  );
  return (
    <Dialog
      header={
        <div className="flex flex-row items-center gap-3">
          <img src={emojis_cookie} alt="cookie icon" />
          <p>{t("cookieModal.header")}</p>
        </div>
      }
      visible={open}
      maskStyle={{ animation: "none", pointerEvents: "none" }}
      style={{
        width: "520px",
        padding: "2rem",
        boxShadow: "0px 16px 24px 0px rgba(0, 0, 0, 0.3)",
      }}
      onHide={onClose}
      position="bottom-left"
      draggable={false}
      headerStyle={{
        borderRadius: "none",
        borderTopLeftRadius: "0 !important",
        borderTopRightRadius: "0 !important",
      }}
      footer={footerContent}
    >
      <p className="text-[12px] font-normal text-[#5E5E62] leading-[20px] tracking-[0.1px]">
        {t("cookieModal.content-part1")}{" "}
        <Link to="/privacy" className="underline text-[#002c72]">
          {t("cookieModal.content-cta")}
        </Link>{" "}
        {t("cookieModal.content-part2")}
      </p>
    </Dialog>
  );
};

export default CookieModal;
