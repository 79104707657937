import React from "react";
import customer_reviews_icon from "../../../assets/icons/customer-reviews-icon.svg";
import custom_img from "../../../assets/images/custom-img.png";

import { useTranslation } from "react-i18next";

const ApraisalFromCustomer = () => {
  const { t } = useTranslation("home");

  return (
    <div className="py-20 md:py-32 space-y-6 md:space-y-12">
      <div className="container mx-auto">
        <h3 className="max-w-2xl flex flex-col text-3xl lg:text-[56px] lg:leading-[4rem] font-test-sohne-medium">
          {t("appraisal.appraisalBold")}
        </h3>
      </div>

      <div className="flex flex-row w-full sm:w-full overflow-auto scrollbar-hide space-x-2 md:space-x-4 relative">
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.john.testimonyJohn")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.john.johnName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.shile.testimonyShile")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.shile.shileName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shilePost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shileCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.omotola.testimonyOmotola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.omotola.omotolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.tola.testimonyTola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.tola.tolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaCompany")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaPost")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.john.testimonyJohn")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.john.johnName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.shile.testimonyShile")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.shile.shileName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shilePost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shileCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.omotola.testimonyOmotola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.omotola.omotolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.tola.testimonyTola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.tola.tolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaCompany")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaPost")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.john.testimonyJohn")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.john.johnName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.shile.testimonyShile")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.shile.shileName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shilePost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shileCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.omotola.testimonyOmotola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.omotola.omotolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.tola.testimonyTola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.tola.tolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaCompany")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaPost")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.john.testimonyJohn")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.john.johnName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.shile.testimonyShile")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.shile.shileName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shilePost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shileCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.omotola.testimonyOmotola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.omotola.omotolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.tola.testimonyTola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.tola.tolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaCompany")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaPost")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.john.testimonyJohn")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.john.johnName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.shile.testimonyShile")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.shile.shileName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shilePost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shileCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.omotola.testimonyOmotola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.omotola.omotolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.tola.testimonyTola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.tola.tolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaCompany")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaPost")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.john.testimonyJohn")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.john.johnName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.shile.testimonyShile")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.shile.shileName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shilePost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shileCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.omotola.testimonyOmotola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.omotola.omotolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.tola.testimonyTola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.tola.tolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaCompany")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaPost")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.john.testimonyJohn")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.john.johnName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.john.johnCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.shile.testimonyShile")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.shile.shileName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shilePost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.shile.shileCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.omotola.testimonyOmotola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.omotola.omotolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaPost")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.omotola.omotolaCompany")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="bg-BlueChalk px-6 py-8 rounded-2xl space-y-8 text-CharcoalGrey">
            <img src={customer_reviews_icon} alt="" />
            <p className="text-sm md:text-[15px] h-52">
              {t("appraisal.appraisalCard.tola.testimonyTola")}
            </p>

            <div className="flex flex-row items-center space-x-3">
              <img
                src={custom_img}
                className="rounded-full w-12 h-12"
                alt="customer"
              />
              <div className="">
                <h4 className="font-test-sohne-medium text-base md:text-xl">
                  {t("appraisal.appraisalCard.tola.tolaName")}
                </h4>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaCompany")}
                </p>
                <p className="text-[10px] md:text-xs">
                  {t("appraisal.appraisalCard.tola.tolaPost")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApraisalFromCustomer;

